import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import { searchKeys, columns } from "./config/index";
import MediaForm from "./components/MediaForm";
import LabelManageApi from "@/api/labelManage";
var statusList = [{
  id: '',
  name: '全部'
}, {
  id: '1',
  name: '正常'
}, {
  id: '2',
  name: '停用'
}];
export default {
  components: {
    MediaForm: MediaForm
  },
  data: function data() {
    return {
      editId: '',
      loading: false,
      searchKeys: searchKeys,
      createForm: this.$form.createForm(this, {
        name: "whitelist_index"
      }),
      createFormValues: {},
      columns: columns,
      total: 0,
      page: 1,
      size: 20,
      isDeleteVisible: false,
      deleting: false,
      dataSource: [],
      tipsDesc: '',
      modalTitle: '新增/编辑',
      addVersionVisible: false,
      updateStatus: 0,
      checkItem: {}
    };
  },
  mixins: [Mixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        switch (key) {
          case 'status':
            return statusList;
        }
      };
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {
    if (+this.$route.query.addType == 1) {
      this.createForm.resetFields();
      this.page = 1;
      this.size = 20;
    }
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var params = {
        size: this.size,
        page: this.page
      };
      LabelManageApi.list(params).then(function (res) {
        _this.loading = false;
        _this.dataSource = res.list;
        _this.total = res.total;
      }).catch(function () {
        _this.dataSource = [];
      }).finally(function (e) {
        setTimeout(function () {
          _this.loading = false;
        }, 3000);
      });
    },
    handleEdit: function handleEdit(item) {
      this.editId = item.queryid;
      this.addVersionVisible = true;
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    showPopupForm: function showPopupForm() {
      this.editId = '';
      this.addVersionVisible = true;
    },
    handleAddCancle: function handleAddCancle() {
      this.addVersionVisible = false;
    },
    handleDelete: function handleDelete(item) {
      this.editId = item.queryid;
      this.tipsDesc = "确认删除吗？";
      this.isDeleteVisible = true;
    },
    cancel: function cancel() {
      this.isDeleteVisible = false;
      this.releaseVisible = false;
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;
      var params = {
        queryid: this.editId
      };
      LabelManageApi.delete(params).then(function (res) {
        if (res) {
          _this2.isDeleteVisible = false;
          _this2.getList();
        }
      });
    }
  }
};