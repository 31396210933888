var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "标签名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入标签名称!"
        }]
      }],
      expression: "[\n                          'name',\n                          {\n                              rules: [\n                                  { required: true, message: '请输入标签名称!' },\n                              ],\n                          },\n                      ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "255"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "是否进入推荐位"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isrecommend", {
        initialValue: 0
      }],
      expression: "['isrecommend', { initialValue: 0 }]"
    }],
    attrs: {
      size: "large",
      name: "radioGroup"
    },
    on: {
      change: function change($event) {
        return _vm.hiddenRecommendInput($event.target.value);
      }
    }
  }, [_c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v("否")]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("是")])], 1)], 1), _c("a-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShow,
      expression: "isShow"
    }],
    ref: "recommendInput",
    attrs: {
      label: "推荐位排序"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sort", {
        rules: [{
          pattern: /\d$/,
          message: "请输入数字"
        }]
      }],
      expression: "[\n                          'sort',\n                          {\n                              rules: [\n                                  { pattern: /\\d$/, message: '请输入数字'}\n                              ],\n                          },\n                      ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "10"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.updateStatus == 0 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#F5F5F5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1) : _vm._e(), _vm.updateStatus == 1 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("确定"))])], 1) : _vm._e()])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };