import { POST, GET } from '@/utils/util';
var LabelManageApi = {
  list: function list() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/newslabel/list', params);
  },
  delete: function _delete() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/newslabel/delete', params);
  },
  save: function save() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/newslabel/save', params);
  },
  update: function update() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/newslabel/update', params);
  },
  findone: function findone() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/newslabel/findone', params);
  }
};
export default LabelManageApi;